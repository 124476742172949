import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';
import { Analytics } from '@vercel/analytics/react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { RoleSearch } from './scenes/RoleSeach/RoleSearch';
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Root } from './Root';
import { Home } from './scenes/Home/Home';
import SearchContextProvider from './providers/SearchProvider';
import { ApplyRole } from './scenes/ApplyRole/ApplyRole';
import { Privacy } from './scenes/Privacy/Privacy';
import { Terms } from './scenes/Terms/Terms';
import { Contact } from './scenes/Contact/Contact';
const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: 'privacy',
        element: <Privacy />
      },
      {
        path: 'terms',
        element: <Terms />
      },
      {
        path: 'contact',
        element: <Contact />
      },
      {
        path: 'apply',
        element: <RoleSearch />
      },
      {
        path: 'apply/:id',
        element: <ApplyRole />
      },
      {
        path: '',
        element: <Home />
      },
    ]
  }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SearchContextProvider>
      <RouterProvider router={router} >
      </RouterProvider>
    </SearchContextProvider>
    <Analytics/>
    <SpeedInsights/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Link } from 'react-router-dom';
import './heroNarrow.scss';

export function HeroNarrow({ header, body, link, linkText, align, size, isEmpty, hideButton }) {

    return (
        <section className={`hero__narrow hero__narrow--${align} hero__narrow--${size}`}>
            {
                !isEmpty &&
                <div className='hero__body'>
                    <h1>
                        {header}
                    </h1>
                    <p>
                        {body}
                    </p>
                    {
                        !hideButton && <Link className='brand' to={link}>{linkText} </Link>
                    }
                </div>
            }
        </section>
    )

}
import { useContext, useState } from "react";
import { AvailableJobs } from '../../components/AvailableJobs/AvailableJobs';
import { Grid } from '../../components/Grid/Grid';
import { HeroNarrow } from '../../components/HeroNarrow/HeroNarrow';
import { Search } from '../../components/Search/Search';
import { JobList } from "../../components/JobList/JobList";
import { SearchContext } from "../../providers/SearchProvider";
import { Paginator } from "../../components/Paginator/Paginator";
import { useEffect } from "react";
export function RoleSearch() {

    const { searchResults } = useContext(SearchContext);


    const headerText = 'Find Your Dream Job';
    const headerBody = 'Search and apply for local job opportunities in West Yorkshire.';
    const linkText = 'Sign up';
    const link = '/sign-up';

    const subHeaderText = 'Still have questions?';
    const subHeaderBody = 'Sign up for job alerts and newsletters today!';
    const subLinkText = 'Contact us';
    const subLink = '/contact';

    const gridHeader = `Unlock Your Career Potential with Bronte Jobs`;
    const gridBody = `At Bronte Jobs, we provide valuable resources and expert advice to help you excel in your career. Whether you're looking for resume tips, interview guidance, or career advice, we've got you covered. Our team of professionals is dedicated to helping you unlock your full potential and land your dream job. Explore our wide range of services and take the next step towards a successful career.`;

    const subHeader2 = 'Stay Updated with Job Postings';
    const subHeader2Body = 'Sign up for job alerts and newsletters today!';

    useEffect(() => {
        window.document.title = 'Bronte Jobs - Search Page';
    }, [])


    return (
        <>
            <HeroNarrow body={headerBody} header={headerText} link={link} linkText={linkText} align={'left'}></HeroNarrow>
            <Search ></Search>
            {(!searchResults || searchResults.length === 0) && <AvailableJobs loaderOnly={false}></AvailableJobs>}
            {searchResults?.length > 0 && <JobList jobs={searchResults}></JobList>}
            <Paginator></Paginator>
            <HeroNarrow body={subHeaderBody} header={subHeaderText} link={subLink} linkText={subLinkText} align={'centre'} size={'small'}></HeroNarrow>
            <Grid header={gridHeader} body={gridBody}></Grid>
            <HeroNarrow body={subHeader2Body} header={subHeader2} link={subLink} linkText={subLinkText} align={'left'}></HeroNarrow>

        </>
    )
}
import { useEffect } from "react";
import { Grid } from "../../components/Grid/Grid";
import { Hero } from "../../components/Hero/Hero";
import { HeroNarrow } from "../../components/HeroNarrow/HeroNarrow";
import { Carousel } from '../../components/Carousel/Carousel';

export function Home() {

    const header = 'Find Your Dream Job Today';
    const headerBody = 'Discover local job opportunities and apply with ease on Bronte Jobs.';
    const headerLink = '/apply';
    const headerLinkText = 'Search';

    const gridHeader = 'Find Your Dream Job in West Yorkshire with Bronte Jobs';
    const gridBody = `Bronte Jobs offers a powerful search functionality to help you easily discover relevant job listings in your local area. Whether you're looking for a new career opportunity or just starting out, our platform makes it simple to find the perfect job that matches your skills and interests.`;

    useEffect(() => {
        window.document.title = 'Welcome To Bronte Jobs';
    }, [])

    return (
        <>
            <Hero></Hero>
            <Carousel></Carousel>
            <Grid header={gridHeader} body={gridBody}></Grid>
            <HeroNarrow header={header} body={headerBody} link={headerLink} linkText={headerLinkText} align={'centre'}></HeroNarrow>
        </>
    )
}
import { Link } from 'react-router-dom';
import './hero.css';

export function Hero() {

    return (
        <section className="hero__large">
            <div className='hero__body'>
                <h1>
                    Find the Perfect Job in West Yorkshire
                </h1>
                <p>
                    Welcome to Bronte Jobs, the leading job board for local job seekers in West Yorkshire. We specialize in connecting employers with qualified candidates, making the job search process easier and more efficient. Whether you're looking for a full-time position, part-time work, or temporary gigs, Bronte Jobs has a wide range of job listings to suit your needs. Start your job search today and take the next step towards your dream career.
                </p>
                <Link to={'/apply'} className='brand'>Search</Link>
            </div>
        </section>
    )

}
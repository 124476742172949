import { useEffect, useRef, useState } from "react";
import { HeroNarrow } from "../../components/HeroNarrow/HeroNarrow";
import { Link, useParams } from "react-router-dom";
import { ChevronLeft, Share } from '@mui/icons-material'
import { AvailableJobs } from "../../components/AvailableJobs/AvailableJobs";
import './ApplyRoles.scss';
import { type } from "@testing-library/user-event/dist/type";

export function ApplyRole() {

    const [role, setRole] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isIntersecting, setIsIntersecting] = useState(true);
    const { id } = useParams();
    const elmRef = useRef();
    const baseUrl = 'https://api.brontejobs.com/api/roles/search/';


    useEffect(() => {

        fetch(baseUrl + id).then(o => o.json()).then(resp => {
            resp = datafix(resp);
            setRole(resp);
            window.document.title = `Apply for ${resp.jobTitle}`;
            setIsLoading(false);
        });

    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIsIntersecting(entry.isIntersecting);
            console.log(isIntersecting);
        },
            { rootMargin: "-100px" }
        );

        if (elmRef.current !== undefined) {
            observer.observe(elmRef.current);
        }

        return () => observer.disconnect();
    }, [elmRef.current]);


    const datafix = (resp) => {

        try {
            if (resp.jobLocation && Array.isArray(resp.jobLocation)) {
                resp.jobLocation = resp.jobLocation[0];
            }

            if (resp.company && resp.hiringOrganization && resp.company === 'asda') {
                resp.hiringOrganization.logo = 'https://www.asda.jobs/image/776050/690a4525-b9c6-46be-8e1e-a50486acadc0/200/60'
            }

            return resp;
        }
        catch {
            console.warn('failed data fix');
            return resp;
        }
    }

    const getData = () => {

        if (!role.applicationData.startsWith('{')) {
            const pared = JSON.parse(role.applicationData);
            return pared;
        }
        return role.applicationData;
    }

    const onShare = () => {
        try {
            navigator.share({
                title: `${role.jobTitle}`,
                text: "from Bronte Jobs",
                url: window.location.href,
            });
        }
        catch {
            console.warn('share error')
        }
    }


    return (
        <>
            <HeroNarrow isEmpty={true}></HeroNarrow>
            <section className="applyrole">
                <AvailableJobs isLoading={isLoading} loaderOnly={true}></AvailableJobs>


                {role &&
                    <>
                        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: getData() }} ></script>
                        <div className="applyrole__back">
                            <Link to={-1}>
                                <ChevronLeft></ChevronLeft>Back
                            </Link>

                            <span className={`applyrole__actions applyrole__actions--${!isIntersecting ? 'visible' : ''}`}>
                                <button className="brand__white" onClick={() => { onShare() }}>
                                    <Share ></Share>
                                </button>
                                <Link className="brand__white" to={role.uniqueValue}>Apply Now</Link>
                            </span>

                        </div>


                        <h1 className="applyrole__title">
                            {role.jobTitle}
                        </h1>

                        <div className="applyrole__grid">
                            <div className="applyrole__description" dangerouslySetInnerHTML={{ __html: role.description }}>
                            </div>
                            <div className="applyrole__points">

                                <div className="points__item points__item--image">
                                    <img height={40} src={role.hiringOrganization.logo} />
                                </div>
                                <div className="points__item">
                                    <h2 className="points__header">Hiring company</h2>
                                    <p className="points__text">{role.hiringOrganization?.name}</p>
                                </div>

                                <div className="points__item">
                                    <h2 className="points__header">Salary</h2>
                                    <p className="points__text">{role.baseSalaryValue}</p>
                                </div>

                                <div className="points__item">
                                    <h2 className="points__header">Employment Type</h2>
                                    <p className="points__text">{role.employmentType}</p>
                                </div>

                                <div className="points__item">
                                    <h2 className="points__header"> Location</h2>
                                    <p className="points__text">{role.jobLocation.address.postalCode}</p>
                                </div>

                                <div className="points__item points__item--apply" ref={elmRef}>
                                    {/* {
                                        navigator.share &&
                                    } */}

                                    <button className="brand" onClick={() => { onShare() }}>
                                        <Share ></Share>
                                    </button>
                                    <Link className="brand" to={role.uniqueValue}>Apply Now</Link>
                                </div>
                            </div>
                        </div>
                    </>
                }

            </section>
        </>
    )
}
import { useEffect } from "react";
import { HeroNarrow } from "../../components/HeroNarrow/HeroNarrow";
import './Privacy.scss';

export function Privacy() {


    useEffect(() => {
        window.document.title = 'Bronte Jobs - Privacy Page';
    }, [])

    return (
        <>
            <HeroNarrow isEmpty={true}></HeroNarrow>
            <section className="privacy">
                <h1>Privacy Policy</h1>
                <p>
                    This Privacy Policy applies to the website located at brontejobs.com (the “Site”). The Site does not collect any personal data from its users, including IP addresses and geolocations. However, the Site will collect user email addresses, provided the address has been volunteered by the user.
                    If you have any questions about this Privacy Policy, please contact us at <a href="mailto::info@brontejobs.com">info@brontejobs.com</a>.
                </p>
            </section>
        </>
    )
}
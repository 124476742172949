import { Link } from "react-router-dom";
import './ContactForm.scss'

export function ContactForm() {


    return (
        <section className="contactform">
            <h2>Get in touch</h2>
            <p>Have a question or need assistance? Contact us!</p>
            <form>
                <label className="input">
                    <span>Name</span>
                    <input type="text" />
                </label>
                <label className="input">
                    <span>Email</span>
                    <input type="email" />
                </label>
                <label className="input">
                    <span>Message</span>
                    <textarea rows={5}></textarea>
                </label>
                <label className="checkbox">
                    <input type="checkbox" /><span>I agree to the <Link to={'/terms'}>terms and Conditions</Link></span>
                </label>
                <button className="brand" type="submit">Submit</button>

            </form>
        </section>
    )

}
import { useContext } from 'react';
import './Paginator.scss';
import { SearchContext } from '../../providers/SearchProvider';


export function Paginator() {

    const { currentPage, pageCount, updateSearch } = useContext(SearchContext);

    const generateBlocks = () => {

        const blocks = [];
        for (let i = 1; i <= pageCount; i++) {
            blocks.push({
                page: i,
                currentPage: currentPage
            });
        }

        return blocks.map(block => {
            return <li key={block.page}  >
                <button onClick={() => { updateSearch(block.page) }} className={`${block.currentPage === block.page ? 'current' : ''}`}>
                    {block.page}
                </button>
            </li>
        })
    }

    return (
        pageCount > 1 &&
        <ul className='paginator'>
            {generateBlocks()}
        </ul>
    )
}
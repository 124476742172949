import './Input.scss';

export function Input({ placeholder, onChange, value, datalist }) {

    return (
        <div className='input__wrapper'>
            <input placeholder={placeholder} onChange={onChange} value={value} list='options'/>
            {
                datalist && datalist.length > 0 &&
                <datalist id='options'>
                    {
                        datalist.map(item =>{
                            return <option value={item.display_name} key={item.place_id}></option>
                        })
                    }
                </datalist>
            }
        </div>
    )
}
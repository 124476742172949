import { useState } from "react"
import './FAQList.scss'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export function FAQList() {

    const list = [
        {
            title: 'How do I sign up?',
            body: `To sign up, simply visit our website and click on the 'Sign Up' button. Fill in the required information and create your account. Once registered, you can start browsing and applying for jobs.`
            , open: false,
            id: 1
        },
        {
            title: 'How do I search for jobs?',
            body: `To search for jobs, use the search bar on our homepage. Enter relevant keywords, location, and other filters to refine your search. Unfortunately, browsing through different job categories is not available at this time.`
            , open: false,
            id: 2
        },
        {
            title: 'How do I apply for a job?',
            body: `To apply for a job, click on the job listing you're interested in. Read the job description and requirements carefully, then click on the 'Apply Now' button to be directed to the employer's application page. Follow the instructions to submit your application.`
            , open: false,
            id: 3
        },
        {
            title: 'How can I contact support?',
            body: `If you need assistance or have any questions, you can contact our support team by sending an email to info@brontejobs.com or by filling out the contact form on our website. We'll get back to you as soon as possible.`
            , open: false,
            id: 4
        },
    ]

    const [listItems, setListItems] = useState(list);

    const onAccordionClick = (id) => {
        const itemIndex = listItems.findIndex(o => o.id === id);
        listItems[itemIndex].open = !listItems[itemIndex].open;
        console.log(listItems);
        setListItems([...listItems])
    }

    return (
        <div className="faqs">
            <h2>Frequently Asked Questions</h2>
            <p>Find answers to common questions about our support and services.</p>

            <ul className="accordion">
                {
                    listItems.map(item => {
                        return (
                            <li onClick={() => { onAccordionClick(item.id) }} key={item.id} className={`accordion__item accordion__item--${item.open ? 'open' : 'closed'}`}>
                                <p className="accordion__title"><span>{item.title}</span> <ExpandMoreIcon  fontSize="large"></ExpandMoreIcon></p>
                                <div className="accordion__body">
                                    <p>{item.body}</p>
                                </div>
                            </li>)
                    })
                }
            </ul>
        </div>
    )

}
import { Link } from 'react-router-dom';
import './navbar.css';


export function Navbar() {

    return (
        <header className='navbar'>
            <div>
                <Link to='/'>Discover</Link>
                <Link to='/apply'>Search</Link>
                <Link to="/contact">Contact us</Link>
            </div>
            <div className='navbar__logo'>
                Bronte Jobs
            </div>
        </header>
    )
}
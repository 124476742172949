import { useEffect } from "react";
import { ContactForm } from "../../components/ContactForm/ContactForm";
import { FAQList } from "../../components/FAQList/FAQList";
import { HeroNarrow } from "../../components/HeroNarrow/HeroNarrow";
import './Contact.scss';

export function Contact() {

    useEffect(() => {
        window.document.title = 'Bronte Jobs - Contact us';
    }, [])

    return (
        <>
            <HeroNarrow align={'left'} hideButton={true} header={'Contact Us'} body={'Welcome to our Contact Us page. We value your feedback and are here to assist you.'}></HeroNarrow>
            <section className="contact">
                <ContactForm></ContactForm>
                <FAQList></FAQList>
            </section>
        </>
    )
}
import { useContext } from 'react';
import { SearchContext } from '../../providers/SearchProvider';
import './availableJobs.scss';

export function AvailableJobs({ loaderOnly }) {

    const { searchResults, isLoading } = useContext(SearchContext);

    return (
        <section className={`availableJobs availableJobs--${isLoading} availableJobs__loader--${loaderOnly}`}>
            {(isLoading) &&
                <div className="lds-facebook"><div></div><div></div><div></div></div>
            }
            {
                (!isLoading && !loaderOnly && searchResults.length <= 0) &&
                <><h2>1000+ Available jobs</h2><p>Browse through our extensive list of job opportunities in various industries and locations.</p></>
            }
        </section>
    )
}
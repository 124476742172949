import { Outlet, useLocation } from "react-router-dom";
import { Footer } from "./components/Footer/Footer";
import { Navbar } from "./components/NavBar/NavBar";
import { useEffect } from "react";

export function Root() {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <Navbar>
            </Navbar>
            <Outlet />
            <Footer></Footer>
        </>
    )
}